import React from 'react'
import { TiSocialFacebook ,TiSocialLinkedin } from "react-icons/ti";
import { SlSocialInstagram,SlSocialLinkedin  } from "react-icons/sl";
 

function Footer() {
  return (
    <div className='footer'>
      <div className="footerheader">
        {/* <div className="footerlogo">
          <img src="https://yourgutmap.co.uk/wp-content/uploads/2023/03/ygm-logo.png" alt="" />
        </div> */}
        <div className="lista">
          <h2>About</h2>
          <ul>
            <li>Microbiome</li>
            <li>Food sensitvity</li>
            <li>science</li>
            <li>Shop</li>

          </ul>
        </div>
        <div className="lista">

          <h2>Connect</h2>
          <ul>

            <li>Contact</li>
            <li>Practitioners</li>

          </ul>
        </div>
        <div className="lista">
          <h2>Address</h2>
          <ul>

            <li>yourgutMap</li>
            <li>The Catalyst</li>
            <li>York Science Park</li>
            <li>York</li>
            <li>YO1O5GA</li>

          </ul>
        </div>
        <div className="lista">
          <h2>follow Us</h2>
<div className="icons">
<div className="icon-box"><a href=""><TiSocialFacebook /></a></div>
<div className="icon-box"><a href=""><TiSocialLinkedin  /></a></div>
<div className="icon-box"><a href="">< SlSocialInstagram/></a></div>
  
</div>
        </div>
      </div>
      <div className="footerend">
        <div className='footerendleft'>
          <a href="">Privacy Policy</a>
          <a href="">Term & condition</a>


        </div>
        <div className="footerendright">
          <p>YourGutMap Copyright 2024-25 © All rights Reserved.</p>
        </div>

      </div>


    </div>
  )
}

export default Footer
