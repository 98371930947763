import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Toparticlesfirstpage from './toparticlesfirstpage'
import { PiSealQuestionFill } from 'react-icons/pi'
import { Color } from 'antd/es/color-picker'
import {  MdArrowDropDown, MdArrowDropUp} from 'react-icons/md'; 
function Toparticles() {

  const [isOpen, setIsOpen] = useState(true);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };
  const [open, setopen] = useState("")
  const [question, setquestion] = useState([])

  useEffect(() => {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallfaq", requestOptions)
      .then(response => response.json())
      .then(result => setquestion(result))


  }, [])


  return (
    <div className='toparticlesmaindiv'>

      {question.map((value, index) => {
        return (<>
          <div className='articlesdiv'  onClick={() => { setopen(index) }} style={{cursor:'pointer', color: open === index?  '#4180B7'  :'#6A4FA0' }}>
            <div className='articlestext' >
              <span>
                <h3  >{value.question}</h3>

                {open === index && <>

                  <p>

                    <span dangerouslySetInnerHTML={{ __html: value.answer.replace(/\n/g, "<br />") }} />

                  </p>
                </>}
              </span>

            <div className='iconbox' style={{backgroundColor: open === index?  '#4180B7'  :'#6A4FA0',
            height:"50px", 
            width:"50px", 
            borderRadius:"50%",
            display:"flex",
            alignItems:"center",
            justifyContent:"center"

            }} onClick={toggleAnswer}>
              {/* <PiSealQuestionFill style={{width:'35px',height:"35px"}}/> */}
             
              {open === index ? < MdArrowDropUp style={{width:'35px',height:"35px",color:"white"}}/> : < MdArrowDropDown style={{width:'35px',height:"35px" ,color:"white"}}/>}
              </div>


            </div>
          </div>
        </>)
      })}

    </div>
  )
}

export default Toparticles



