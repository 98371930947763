import React, { useEffect, useState } from 'react';
import Header from '../component/header';
import Latestarticles from '../component/latestarticles';
import Toparticles from '../component/toparticles';
import Footer from '../component/footer';
import { useNavigate } from 'react-router-dom'
import ticketpage from './ticketpage';
import Searchbarpage from './searchbarpage';
import { AutoComplete } from 'antd';
import { SiLibreofficewriter } from 'react-icons/si';
import { TfiWrite } from 'react-icons/tfi';
import { BsQuestionCircle } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';

function Index() {
  const search = useNavigate()

  const handelsearchbar = (event) => {
    if (event.key === 'Enter') {
      search('/searching')



    }


  };






  const navigate = useNavigate()
  const Gototicketpage = () => {
    navigate("/ticket")
  }




  const ali = useNavigate()

  const gototokenpage = () => {
    ali("/viewtickets")
  }


  const [options, setOptions] = useState([]);
  const [questions, setQuestions] = useState([]);

  // Fetch questions (FAQs)
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallfaq", requestOptions)
      .then(response => response.json())
      .then(result => {
        // Assuming result is an array of questions
        setQuestions(result);
      })
      .catch(error => console.log('error', error));
  }, []);

  // Handle search (filter questions)
  const handleSearch = (searchText) => {
    if (!searchText) {
      setOptions([]);
      return;
    }

    // Filter the questions that include the search text
    const filteredQuestions = questions
      .filter(item => item.question.toLowerCase().includes(searchText.toLowerCase()))
      .map(item => ({
        value: item.question,
        label: item.question
      }));

    setOptions(filteredQuestions);
  };

  const [searchvalue, setsearchvalue] = useState("")




  return (
    <div className='mainpage'>


      <div className="hello">
       
          <div className='heading'>
            <h2>Hello, how can we help?</h2>
            <p>Serach hundreds of articles on YourGutMap Help </p>
          </div>
          <form className='searchbox' onSubmit={(e) => {
            e.preventDefault()
            ali("/search/" + searchvalue)
          }}>

            <AutoComplete
            
              options={options}
              style={{ width: 200, borderRadius: '50%' }}
              placeholder="Search for article"

              className='autocomple'
              onSearch={handleSearch}  // When typing, call handleSearch
              onSelect={(value) => console.log(value)}  // Handle selection
              onChange={(e) => setsearchvalue(e)}

             required
            />
            <div className="search-icon"> <button style={{background:"none",border:"none"}}><BsSearch /></button></div>
          </form>

          <div className='ticketdiv'>
            <div className='ticketdivleft' onClick={Gototicketpage}>
              {/* <div className='iconbox'><TfiWrite style={{ width: '35px', height: "35px" }} /></div> */}
              <span>
                <h6>Submit a ticket</h6>

              </span>
            </div>
            <div className='ticketdivright' onClick={gototokenpage}>
              {/* <div className='iconbox'><SiLibreofficewriter style={{ width: '35px', height: "35px" }} /></div> */}
              <span>
                <h6>View existing tickets</h6>

              </span>
            </div>

          </div>
      
        {/* <div className="omhdiv"><img src="https://static.vecteezy.com/system/resources/thumbnails/017/784/731/small/men-work-activity-3d-illustration-transparent-background-png.png" alt="" style={{height:"100%", width:"100%"}}/></div> */}

      </div>
      <div className='box1'>
        <h2>YourGutMap</h2>
        <p style={{ margin: '0', lineHeight: '1.5' }}>YourGutMap uses laboratory tests and artificial intelligence softwares to unlock key health data from within your body. Modern diets, lifestyles and medication can cause imbalances in the microbiome – which are linked to chronic inflammation, and metabolic dysfunction – two key influences of many health problems.</p><br></br>

        <p>If you have any questions or need further assistance, feel free to reach out to us! Our team is here to help and will do our best to respond as quickly as possible.</p>
        <div className='boxfooter'>

          <div className='boxright'>
            <h2>» Need More Help?</h2>
            <p style={{ margin: '0', lineHeight: '1.5' }}>If you can’t find the answer you're looking for in our FAQs or need further assistance, you can submit a support ticket. Please include all relevant details, and our team will respond to you as soon as possible. We're committed to helping you resolve any issues quickly and efficiently! </p>
          </div>
        </div>
      </div>

      {/* 
      <div className='buttondiv'>
        <div className='buttonhead'>
          <div className='iconbox'><BsQuestionCircle style={{ width: '35px', height: "35px" }} /></div>
          <h3>FAQs</h3>
        </div>

      </div> */}

      <Toparticles />



    </div>
  );
}

export default Index;
